<template>
  <div v-if="model && options" class="wrapper">
    <div class="section-wrap section-wrap--setttings">
      <div class="container settings__container">
        <div class="maincontent">
          <template v-if="currentUser && currentUser.blogger_profile.filled">
            <SettingsBlock
              v-if="currentUser.blogger_profile"
              id="statSett"
              ref="statSett"
              :header="'Статистика вашего аккаунта'"
              :subheader="'Для карточки блогера'"
            >
              <template v-slot:content>
                <main
                  class="d-flex flex-row justify-content-between flex-wrap px-2 px-xsm-3 px-llg-4 px-xlg-5"
                >
                  <div class="w-100 set-fields">
                    <h4 class="settings__title--small">
                      Охваты сторис
                      <a
                        @click="showFillExample = true"
                        class="settings__title--blue settings__title--margin--left"
                        >Пример заполнения</a
                      >
                    </h4>
                    <b-form
                      class="align-items-end flex-nowrap d-flex flex-row justify-content-between"
                    >
                      <b-form-group class="w-30 mr-1">
                        <div class="settings__info--text--up">Минимум</div>
                        <b-input
                          v-symbolic-filter="/[^0-9]/g"
                          v-model.number="model.blogger_profile.coverage_min"
                          :class="{
                            error: $v.model.blogger_profile.coverage_min.$anyError,
                          }"
                          class="h-36"
                          type="number"
                          name="stories"
                          id="stories1"
                        />
                      </b-form-group>
                      <b-form-group class="w-30 mr-1">
                        <div class="settings__info--text--up">Максимум</div>
                        <b-input
                          v-symbolic-filter="/[^0-9]/g"
                          v-model.number="model.blogger_profile.coverage_max"
                          :class="{
                            error: $v.model.blogger_profile.coverage_max.$anyError,
                          }"
                          type="number"
                          name="stories"
                          id="stories22"
                          class="h-36"
                        />
                      </b-form-group>
                      <b-form-group class="mr-1"
                        ><SingleFileInput
                          @input="
                            (val) => {
                              uploadScreens({ coverage: val });
                              coverageScreen = val;
                            }
                          "
                          :value="coverageScreen"
                          :selected="
                            model.blogger_profile.coverage_screen.hasOwnProperty('full_size')
                          "
                      /></b-form-group>
                    </b-form>
                    <div class="settings__info--text--down"></div>
                  </div>
                  <div class="w-100 set-fields">
                    <h4 class="settings__title--small">
                      Охваты постов
                      <a
                        @click="showFillExample = true"
                        class="settings__title--blue settings__title--margin--left"
                        >Пример заполнения</a
                      >
                    </h4>
                    <b-form
                      class="align-items-end flex-nowrap d-flex flex-row justify-content-between"
                    >
                      <b-form-group class="w-30 mr-1">
                        <div class="settings__info--text--up">Минимум</div>
                        <b-input
                          v-symbolic-filter="/[^0-9]/g"
                          :class="{
                            error: $v.model.blogger_profile.coverage_posts_min.$anyError,
                          }"
                          v-model.number="model.blogger_profile.coverage_posts_min"
                          type="number"
                          class="h-36"
                          name="stories"
                          id="stories3"
                        />
                      </b-form-group>
                      <b-form-group class="w-30 mr-1">
                        <div class="settings__info--text--up">Максимум</div>
                        <b-input
                          v-symbolic-filter="/[^0-9]/g"
                          :class="{
                            error: $v.model.blogger_profile.coverage_posts_max.$anyError,
                          }"
                          v-model.number="model.blogger_profile.coverage_posts_max"
                          type="number"
                          class="h-36"
                          name="stories"
                          id="stories4"
                        />
                      </b-form-group>
                      <b-form-group class="mr-1"
                        ><SingleFileInput
                          @input="
                            (val) => {
                              uploadScreens({ posts: val });
                              postCoverageScreen = val;
                            }
                          "
                          :value="postCoverageScreen"
                          :selected="
                            model.blogger_profile.posts_coverage_screen.hasOwnProperty('full_size')
                          "
                      /></b-form-group>
                    </b-form>

                    <div class="settings__info--text--down settings__info--text--down--error"></div>
                  </div>
                  <div class="w-100 set-fields">
                    <h4 class="settings__title--small">
                      Процент женщин
                      <a
                        @click="showFillExample = true"
                        class="settings__title--blue settings__title--margin--left"
                        >Пример заполнения</a
                      >
                    </h4>
                    <b-form
                      class="align-items-end flex-nowrap d-flex flex-row justify-content-start"
                    >
                      <b-form-group class="w-25 mr-3">
                        <b-input
                          v-symbolic-filter="/[^0-9]/g"
                          :class="{ error: $v.model.blogger_profile.females_percent.$anyError }"
                          v-model.number="model.blogger_profile.females_percent"
                          type="number"
                          class="h-36 statistics__percent"
                          name="stories"
                          id="stories5"
                        />
                      </b-form-group>
                      <b-form-group class="mr-1">
                        <SingleFileInput
                          @input="
                            (val) => {
                              uploadScreens({ women: val });
                              womenScreen = val;
                            }
                          "
                          :value="womenScreen"
                          :selected="
                            model.blogger_profile.women_percent_screen.hasOwnProperty('full_size')
                          "
                      /></b-form-group>
                    </b-form>

                    <div class="settings__info--text--down"></div>
                  </div>
                  <div class="w-100 set-fields">
                    <h4 class="settings__title--small">
                      Преобладающая страна
                      <a
                        @click="showFillExample = true"
                        class="settings__title--blue settings__title--margin--left"
                        >Пример заполнения</a
                      >
                    </h4>
                    <b-form
                      class="align-items-end flex-nowrap d-flex flex-row justify-content-between"
                    >
                      <b-form-group class="w-35 mr-1">
                        <Multiselect
                          :class="{
                            error: $v.model.blogger_profile.main_country.country.$anyError,
                          }"
                          class="country__select"
                          v-model="model.blogger_profile.main_country.country"
                          placeholder="Выберите страну"
                          :show-labels="false"
                          :options="countries"
                          track-by="id"
                          label="name"
                        />
                      </b-form-group>
                      <b-form-group class="w-25 mr-1">
                        <b-input
                          v-symbolic-filter="/[^0-9]/g"
                          :class="{
                            error: $v.model.blogger_profile.main_country.coverage.$anyError,
                          }"
                          v-model.number="model.blogger_profile.main_country.coverage"
                          type="number"
                          class="h-36 text-left"
                          name="stories"
                          id="stories6"
                        />
                      </b-form-group>
                      <b-form-group class="mr-1">
                        <SingleFileInput
                          @input="
                            (val) => {
                              uploadScreens({ country: val });
                              countryScreen = val;
                            }
                          "
                          :value="countryScreen"
                          :selected="
                            model.blogger_profile.main_country.screen.hasOwnProperty('full_size')
                          "
                      /></b-form-group>
                    </b-form>
                    <div class="settings__info--text--down settings__info--text--down--error"></div>
                  </div>
                  <div class="w-100 set-fields">
                    <h4 class="settings__title--small">
                      Преобладающий возраст
                      <a
                        @click="showFillExample = true"
                        class="settings__title--blue settings__title--margin--left"
                        >Пример заполнения</a
                      >
                    </h4>
                    <b-form
                      class="align-items-end flex-nowrap d-flex flex-row justify-content-between"
                    >
                      <b-form-group class="w-35 mr-1">
                        <Multiselect
                          :class="{
                            error: $v.model.blogger_profile.main_audience.age_group.$anyError,
                          }"
                          :value="currentAudience"
                          @input="
                            (val) => (model.blogger_profile.main_audience.age_group = val.value)
                          "
                          placeholder="Возраст"
                          track-by="value"
                          label="display_name"
                          :show-labels="false"
                          :options="options.audiences.child.children.age_group.choices"
                        />
                      </b-form-group>
                      <b-form-group class="w-25 mr-1">
                        <b-input
                          v-symbolic-filter="/[^0-9]/g"
                          :class="{
                            error:
                              $v.model.blogger_profile.main_audience.age_group_percent.$anyError,
                          }"
                          v-model.number="model.blogger_profile.main_audience.age_group_percent"
                          type="number"
                          class="h-36 text-left"
                          name="stories"
                          id="stories7"
                        />
                      </b-form-group>
                      <b-form-group class="mr-1">
                        <SingleFileInput
                          @input="
                            (val) => {
                              uploadScreens({ audience: val });
                              audienceScreen = val;
                            }
                          "
                          :value="audienceScreen"
                          :selected="
                            model.blogger_profile.main_audience.screen.hasOwnProperty('full_size')
                          "
                      /></b-form-group>
                    </b-form>
                    <div class="settings__info--text--down"></div>
                  </div>
                  <div class="w-100 set-fields">
                    <h4 class="settings__title--small">
                      Преобладающий город
                      <a
                        @click="showFillExample = true"
                        class="settings__title--blue settings__title--margin--left"
                        >Пример заполнения</a
                      >
                    </h4>
                    <b-form
                      class="align-items-end flex-nowrap d-flex flex-row justify-content-between"
                    >
                      <b-form-group class="w-35 mr-1">
                        <CitySelect
                          v-model="model.blogger_profile.main_city.city"
                          deselectLabel=""
                          selectedLabel=""
                        />
                      </b-form-group>
                      <b-form-group class="w-25 mr-1">
                        <b-input
                          v-symbolic-filter="/[^0-9]/g"
                          :class="{ error: $v.model.blogger_profile.main_city.coverage.$anyError }"
                          v-model.number="model.blogger_profile.main_city.coverage"
                          type="number"
                          class="h-36 text-left"
                          name="stories"
                          id="stories8"
                        />
                      </b-form-group>
                      <b-form-group class="mr-1">
                        <SingleFileInput
                          @input="
                            (val) => {
                              uploadScreens({ city: val });
                              cityScreen = val;
                            }
                          "
                          :value="cityScreen"
                          :selected="
                            model.blogger_profile.main_city.screen.hasOwnProperty('full_size')
                          "
                      /></b-form-group>
                    </b-form>

                    <div class="settings__info--text--down"></div>
                  </div>
                </main>
                <div class="settings__info--footer settings__info--footer--common">
                  <b-button @click="submit" :variant="modelChanged ? 'primary' : 'outline-default'"
                    >Сохранить изменения</b-button
                  >
                </div></template
              >
            </SettingsBlock>
            <SettingsBlock
              v-if="currentUser.blogger_profile"
              id="themeSett"
              header="Темы аккаунта и тезисы"
              subheader="Для карточки блогера и ВП на завтра"
            >
              <template v-slot:content>
                <main class="settings__themes">
                  <div class="settings__themes--wrap">
                    <b-form-group>
                      <h3 class="settings__title--small">Тезисы</h3>
                      <textarea
                        v-model="model.blogger_profile.theses"
                        name="themes-textarea"
                        class="settings__themes--textarea"
                      ></textarea>
                    </b-form-group>
                  </div>
                  <div class="settings__themes--wrap">
                    <div class="setting__themes--block">
                      <h3 class="settings__title--small">Укажите 1-3 темы вашего аккаунта</h3>
                      <Multiselect
                        v-model="model.blogger_profile.tags"
                        placeholder="Выберите из списка"
                        :multiple="true"
                        :max="3"
                        :show-labels="false"
                        :options="tags"
                        track-by="name"
                        label="name"
                      />
                    </div>
                    <div class="setting__themes--block">
                      <h3 class="settings__title--small">Какие темы не рекламируете</h3>
                      <textarea
                        v-model="model.blogger_profile.excluded_tags"
                        name="themes-textarea"
                        style="font-size: 14px"
                        placeholder="Например: Cетевой бизнес, астрология, гадания, казино."
                        class="settings__themes--textarea settings__themes--textarea--2"
                      ></textarea>
                    </div>
                  </div>
                </main>
                <div class="settings__info--footer settings__info--footer--common">
                  <b-button @click="submit" :variant="modelChanged ? 'primary' : 'outline-default'"
                    >Сохранить изменения</b-button
                  >
                </div>
              </template>
            </SettingsBlock>
            <SettingsBlock
              v-if="currentUser.blogger_profile"
              id="kindSett"
              contentClass="kinds-sett-content"
              header="Форматы рекламы"
              subheader="Для карточки блогера"
            >
              <template v-slot:content>
                <b-button @click="increment" class="format_pr-btn-sett" variant="outline-default"
                  >Добавить новый формат рекламы</b-button
                >
                <PrTypeForm
                  @delete="decompose(type.uid)"
                  v-model="model.blogger_profile.pr_types[getIdxById(type.uid)]"
                  :ref="type.uid"
                  :options="options"
                  v-for="(type, idx) in model.blogger_profile.pr_types"
                  :key="`${type.uid}-${idx}-prtpFrmMn`"
                />
                <div
                  class="settings__info--footer settings__info--footer--common pr-types-sett__footer"
                >
                  <b-button @click="submit" :variant="modelChanged ? 'primary' : 'outline-default'"
                    >Сохранить изменения</b-button
                  >
                </div>
              </template>
            </SettingsBlock>
          </template>
          <template v-else>
            <SettingsBlock
              id="statSett"
              :foldable="false"
              header="Настройки карточки"
              :subheader="'Аккаунт easyprbot'"
            >
              <template #content>
                <div class="p-4 d-flex flex-column align-items-center justify-content-between">
                  <p class="text-center">Вы недозаполнили карточку блогера! Настройки недоступны</p>
                  <b-button class="mt-3" :to="{ name: 'fill_statistics' }" variant="yellow"
                    >Заполнить карточку</b-button
                  >
                </div>
              </template>
            </SettingsBlock>
          </template>
          <SettingsBlock
            id="defSett"
            :foldable="false"
            header="Общие настройки"
            :subheader="'Аккаунт easyprbot'"
          >
            <template v-slot:content>
              <main class="settings__common">
                <div
                  class="settings__info--footer settings__footer--mobile settings__info--footer--common"
                >
                  <b-button @click="submit" :variant="modelChanged ? 'primary' : 'outline-default'"
                    >Сохранить изменения</b-button
                  >
                </div>
                <div class="settings__common--wrap">
                  <div class="settings__common--block">
                    <h4
                      class="settings__title--small"
                      style="
                        display: flex;
                        justify-content: space-between;
                        width: 94%;
                        align-items: center;
                      "
                    >
                      <span>Ваш телефон</span>
                      <span @click="showPhoneModal = true" class="settings__title--blue"
                        >Изменить</span
                      >
                    </h4>
                    <PhoneMaskInput
                      autoDetectCountry
                      disabled
                      id="tel"
                      inputClass="settings__input"
                      :value="
                        currentUser.profile.phone
                          ? `+${currentUser.profile.phone}`
                          : '+79999999999 (не указан)'
                      "
                      placeholder="В формате, +7 (999) 999-99-99"
                    />
                    <div
                      :title="currentUser.profile.phone ? '' : 'Сначала укажите номер телефона'"
                      :style="{ color: currentUser.profile.phone ? '' : 'lightgray' }"
                      @click="
                        () =>
                          toggleChangePasswordModal({
                            open: true,
                            context: {
                              phone: currentUser.profile.phone ? currentUser.profile.phone : null,
                            },
                          })
                      "
                      class="settings__title--blue settings__title--blue--psw"
                    >
                      Изменить пароль
                    </div>
                  </div>
                  <div style="position: relative" class="settings__common--block">
                    <!--div
                      title="Скоро!"
                      style="
                        opacity: 0.6;
                        background: white;
                        z-index: 5;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                      "
                    ></!--div-->
                    <h4
                      class="settings__title--small"
                      style="
                        display: flex;
                        justify-content: space-between;
                        width: 94%;
                        align-items: center;
                      "
                    >
                      <div class="settings__title--small--text">Ваш e-mail</div>
                      <!--div class="freeauto__date--icon">
                        <div class="freeauto__date--quest">?</div>
                      </div-->
                      <b-button
                        variant="link"
                        :disabled="!validateEmail(model.profile.email)"
                        style="margin: auto 0 auto auto"
                        @click="changeEmail"
                        class="settings__title--blue"
                        >Сохранить</b-button
                      >
                    </h4>
                    <input
                      type="email"
                      spellcheck="false"
                      name="mail"
                      class="settings__input"
                      v-model="model.profile.email"
                      id="mail"
                    />
                    <div
                      v-if="!currentUser.profile.email_confirmed"
                      @click="showEmailActivation = true"
                      class="settings__title--blue settings__title--blue--psw"
                    >
                      <span style="color: #ff0000; margin-right: 10px">E-mail не подтвержден.</span
                      >Подтвердить
                    </div>
                  </div>
                </div>
                <template v-if="currentUser.blogger_profile && currentUser.blogger_profile.filled">
                  <div class="settings__common--wrap">
                    <div class="settings__common--block">
                      <h4 class="settings__title--small">Прикрепленный аккаунт Инстаграм</h4>
                      <div class="settings__title--blue settings__title--blue--psw settings--nick">
                        {{ currentUser.blogger_profile.instaname }}
                      </div>
                      <div class="settings__common--opac">
                        Если вы измените ник этого аккаунта в Инстаграме, то он НЕ изменится на
                        сайте. Для замены ника напишите на
                        <b-button variant="link" href="mailto:help@easyprbot.com"
                          >help@easyprbot.com</b-button
                        >
                      </div>
                    </div>
                    <div class="settings__common--block">
                      <h4 class="settings__title--small">PR-аккаунт</h4>
                      <a
                        v-if="model.blogger_profile.pr_account"
                        :href="`https://instagram.com/${model.blogger_profile.pr_account}`"
                      >
                        <div
                          class="settings__title--blue settings__title--blue--psw settings--nick"
                        >
                          {{ model.blogger_profile.pr_account }}
                        </div>
                      </a>
                      <p v-else style="color: #ff0000" class="">PR-аккаунт не указан!</p>
                      <b-button
                        @click="
                          () =>
                            toggleInstagramVerificationModal({
                              open: true,
                              context: { prAccount: true },
                            })
                        "
                        variant="outline-default"
                        style="margin-top: 9px; font-size: 14px"
                      >
                        {{ model.blogger_profile.pr_account ? 'Изменить' : 'Указать' }} pr-аккаунт
                      </b-button>
                    </div>
                  </div>
                  <div class="settings__common--wrap">
                    <div class="settings__common--block">
                      <h4 class="settings__title--small">
                        Город проживания <span style="opacity: 0.5">(необязательно)</span>
                      </h4>
                      <b-input
                        type="text"
                        name="town"
                        class="settings__input h-36"
                        v-model="model.blogger_profile.current_city"
                        id="town"
                      />
                      <div class="settings__common--opac">
                        Коммерческие аккаунты почти всегда интересуются городом блогеров
                      </div>
                    </div>
                    <div class="settings__common--block">
                      <h4 class="settings__title--small" style="display: flex">
                        <div class="settings__title--small--text">
                          Амбассадорство <span style="opacity: 0.5">(необязательно)</span>
                        </div>
                        <!--div class="freeauto__date--icon">
                        <div class="freeauto__date--quest">?</div>
                      </div-->
                      </h4>
                      <input
                        class="setting__common--check"
                        type="checkbox"
                        v-model="model.blogger_profile.ambassador"
                        id="ambassadorship"
                        name="ambassadorship"
                      />
                      <label class="setting__common--label" for="ambassadorship"
                        >Хочу стать амбассадором</label
                      >
                    </div>
                  </div>
                  <div class="settings__common--wrap">
                    <div class="settings__common--block">
                      <h4 class="settings__title--small">
                        Юридический статус <span style="opacity: 0.5">(необязательно)</span>
                      </h4>
                      <div class="settings__common--checkboxes">
                        <div
                          v-for="(block, idx) in legalStatusList"
                          :key="`${idx}-blcklglSttsLst`"
                          class="settings__common--checkboxes--block"
                        >
                          <label
                            v-for="(i, idx) in block"
                            :key="`${idx}-blckLglSttsaWChck`"
                            class="legal-status"
                          >
                            <input
                              type="radio"
                              :value="i.value"
                              v-model="model.blogger_profile.legal_status"
                              class="legal-status--radio status__check"
                            />
                            <span class="legal-status__block"></span>
                            <span class="legal-status__text">{{ i.display_name }}</span>
                          </label>
                        </div>
                      </div>
                      <template v-if="legalStatusMapping[model.blogger_profile.legal_status]">
                        <div
                          class="settings__common--opac"
                          style="margin-top: 14px; margin-bottom: 8px"
                          title="Недоступен для физ. лиц"
                        >
                          {{ legalStatusMapping[model.blogger_profile.legal_status].name }}
                        </div>
                        <b-input
                          :disabled="model.blogger_profile.legal_status === 1"
                          type="number"
                          name="cipher"
                          class="settings__input h-36"
                          :value="
                            model.blogger_profile[
                              legalStatusMapping[model.blogger_profile.legal_status].model
                            ]
                          "
                          @change="
                            (val) => {
                              model.blogger_profile[
                                legalStatusMapping[model.blogger_profile.legal_status].model
                              ] = val;
                            }
                          "
                          id="cipher"
                        />
                        <div class="settings__common--opac" style="margin-top: 8px">
                          {{ legalStatusMapping[model.blogger_profile.legal_status].name }}
                          необходим для подтверждения вашего юридического статуса и не будет указан
                          на сайте
                        </div>
                      </template>
                    </div>
                    <div style="position: relative !important" class="settings__common--block">
                      <div
                        title="Скоро!"
                        style="
                          opacity: 0.6;
                          background: white;
                          z-index: 5;
                          position: absolute;
                          width: 100%;
                          height: 100%;
                        "
                      ></div>
                      <h4 class="settings__title--small">
                        Какая аудитория вас интересует
                        <div style="opacity: 0.5">(ВП на завтра)</div>
                      </h4>
                      <div class="settings__common--checkboxes">
                        <div class="settings__common--checkboxes--block">
                          <input
                            class="setting__common--check"
                            type="checkbox"
                            id="region"
                            name="region"
                          />
                          <label class="setting__common--label" for="region">Все регионы</label>
                          <input
                            :value="countries.filter((val) => val.short_key === 'RU')[0]"
                            class="setting__common--check"
                            type="checkbox"
                            id="Russia"
                            name="Russia"
                          />
                          <label class="setting__common--label" for="Russia">Россия</label>
                        </div>
                        <div class="settings__common--checkboxes--block">
                          <input
                            :value="countries.filter((val) => val.short_key === 'UA')[0]"
                            class="setting__common--check"
                            type="checkbox"
                            id="Ukraine"
                            name="Ukraine"
                          />
                          <label class="setting__common--label" for="Ukraine">Украина</label>
                          <input
                            :value="countries.filter((val) => val.short_key === 'KZ')[0]"
                            class="setting__common--check"
                            type="checkbox"
                            id="Kazah"
                            name="Kazah"
                          />
                          <label class="setting__common--label" for="Kazah">Казахстан</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="width: 100%" class="settings__common--wrap">
                    <div style="margin-left: 0px !important" class="settings__common--block">
                      <h4 class="settings__title--small">
                        Ссылка на вашу карточку в базе блогеров
                      </h4>
                      <div class="d-flex flex-row w-md-50 w-lg-50 w-sm-100">
                        <b-input
                          type="text"
                          disabled
                          :value="bloggerLink"
                          class="w-lg-25 h-36"
                          name="link"
                          id="link"
                        />
                        <b-button
                          variant="outline-default"
                          v-clipboard:copy="bloggerLink"
                          @click="
                            () => {
                              bloggerLinkCopied = true;
                              window.setTimeout(() => {
                                bloggerLinkCopied = false;
                              }, 1000);
                            }
                          "
                          class="ml-2"
                        >
                          {{ bloggerLinkCopied ? 'Скопировано' : 'Скопировать' }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                </template>
              </main>
              <div class="settings__info--footer settings__info--footer--common">
                <b-button @click="submit" :variant="modelChanged ? 'primary' : 'outline-default'"
                  >Сохранить изменения</b-button
                >
              </div>
            </template>
          </SettingsBlock>
          <!--SettingsBlock id="paySett">
            <template v-slot:content>
              <main class="payment payment--descop">
                <ul class="payment__list">
                  <li
                    class="payment__item"
                    style="
                      padding-bottom: 30px;
                      padding-top: 28px;
                      border-bottom: 1px solid #d5dfe4;
                    "
                  >
                    <h3 class="payment__title">
                      <span class="payment__title--text">Подписка ВП на завтра неактивна</span>
                      <span class="payment__title--icon payment__title--icon--close">
                        <span class="payment__title--wrap--close">×</span></span
                      >
                    </h3>
                    <div class="payment__title payment__title--small">Доступ к трекеру на:</div>
                    <div class="payment__radios">
                      <label class="settings__radio--button payment__radio">
                        <input class="radio" type="checkbox" />
                        <div class="radio__wrap payment-radio__wrap">
                          <div class="radio__block"></div>
                          <div class="radio__content">
                            <div class="radio__text">1 месяц</div>
                            <div class="payment-radio__text">
                              <span>990 ₽</span>
                              <span class="payment-radio__text--opac">/ 33 ₽ в день</span>
                            </div>
                          </div>
                        </div>
                      </label>
                      <label
                        class="settings__radio--button payment__radio"
                        style="margin-bottom: 0"
                      >
                        <input class="radio" type="checkbox" />
                        <div class="radio__wrap payment-radio__wrap">
                          <div class="radio__block"></div>
                          <div class="radio__content">
                            <div class="radio__text">2 месяца</div>
                            <div class="payment-radio__text">
                              <span>1 560 ₽ </span>
                              <span class="payment-radio__text--opac">/ 26 ₽ в день</span>
                            </div>
                          </div>
                        </div>
                      </label>
                      <label class="settings__radio--button payment__radio">
                        <input class="radio" type="checkbox" />
                        <div class="radio__wrap payment-radio__wrap">
                          <div class="radio__block"></div>
                          <div class="radio__content">
                            <div class="radio__text">3 месяца</div>
                            <div class="payment-radio__text">
                              <span>1 980 ₽</span>
                              <span class="payment-radio__text--opac">/ 22 ₽ в день</span>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                    <a  style="text-decoration: none"
                      ><b-button variant="primary" class="pay-btn">Оплатить</b-button></a
                    >
                  </li>
                  <li class="payment__item" style="padding-top: 28px">
                    <h3 class="payment__title">
                      <span class="payment__title--text">Доступ к трекеру неактивен</span>
                      <span class="payment__title--icon payment__title--icon--close">
                        <span class="payment__title--wrap--close">×</span></span
                      >
                    </h3>
                    <div class="settings__common--opac payment__opac">
                      Трекер позволяет: отслеживать результаты рекламы блогеров на основании
                      информации из отзывов; добавлять блогеров в ваш список и отслеживать прогресс
                      своей рекламы; оставлять отзывы о рекламе; отслеживать историю своих отзывов;
                      поставить цель и рассчитать показатели для рекламы у блогеров.
                    </div>
                    <div class="payment__title payment__title--small">Премиум аккаунт на:</div>
                    <div class="payment__radios">
                      <label class="settings__radio--button payment__radio">
                        <input class="radio" type="checkbox" />
                        <div class="radio__wrap payment-radio__wrap">
                          <div class="radio__block"></div>
                          <div class="radio__content">
                            <div class="radio__text">1 месяц</div>
                            <div class="payment-radio__text">
                              <span>990 ₽</span>
                              <span class="payment-radio__text--opac">/ 33 ₽ в день</span>
                            </div>
                          </div>
                        </div>
                      </label>
                      <label
                        class="settings__radio--button payment__radio"
                        style="margin-bottom: 0"
                      >
                        <input class="radio" type="checkbox" />
                        <div class="radio__wrap payment-radio__wrap">
                          <div class="radio__block"></div>
                          <div class="radio__content">
                            <div class="radio__text">2 месяца</div>
                            <div class="payment-radio__text">
                              <span>1 560 ₽ </span>
                              <span class="payment-radio__text--opac">/ 26 ₽ в день</span>
                            </div>
                          </div>
                        </div>
                      </label>
                      <label class="settings__radio--button payment__radio">
                        <input class="radio" type="checkbox" />
                        <div class="radio__wrap payment-radio__wrap">
                          <div class="radio__block"></div>
                          <div class="radio__content">
                            <div class="radio__text radio__text--mobile">3 месяца</div>
                            <div class="payment-radio__text">
                              <span>1 980 ₽</span>
                              <span class="payment-radio__text--opac">/ 22 ₽ в день</span>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                    <a href="#" style="text-decoration: none"
                      ><b-button variant="primary" class="pay-btn">Оплатить</b-button></a
                    >
                  </li>
                </ul>
              </main>
            </template>
          </SettingsBlock-->
          <!-- TODO - ВЕРНУТЬ УВЕДОМЛЕНИЯ ПРИ ВЫГРУЗКЕ ВП -->
          <SettingsBlock
            id="notifSett"
            :foldable="true"
            header="Уведомления"
            :subheader="'Аккаунт easyprbot'"
          >
            <template v-slot:content>
              <main class="notification">
                <div class="notification__block">
                  <div class="notification__title">На почту</div>
                  <div class="notification__title--small">Выберите уведомления</div>
                  <div class="notification__checkboxes">
                    <input
                      @unput="
                        (val) => {
                          toggleEmailNotifications(val);
                        }
                      "
                      :value="currentUser.profile.accept_email"
                      class="setting__common--check notification__checkbox"
                      type="checkbox"
                      id="request"
                      name="request"
                    />
                    <label class="setting__common--label notification__checkbox" for="request"
                      >E-mail уведомления</label
                    >
                  </div>
                  <b-button
                    variant="link"
                    @click="toggleEmailNotifications(false)"
                    class="notification__text--blue"
                    >Отключить все уведомления на почту</b-button
                  >
                </div>
              </main>
            </template>
            <div class="settings__info--footer">
              <b-button @click="submit" :variant="modelChanged ? 'primary' : 'outline-default'"
                >Сохранить изменения</b-button
              >
            </div>
          </SettingsBlock>
        </div>
        <div id="settings__nav">
          <VueStickySidebar
            class="sidebar"
            containerSelector="#settings__nav"
            innerWrapperSelector=".sidebar__inner"
            :topSpacing="85"
          >
            <nav class="navigation">
              <h3 class="navigation__title">Навигация</h3>
              <ul class="navigation__link">
                <template v-if="currentUser.blogger_profile">
                  <li v-hover-to="'statSett'" class="navigation__item">
                    <a class="navigation__link">Статистика аккаунта</a>
                  </li>
                  <li v-hover-to="'themeSett'" class="navigation__item">
                    <a class="navigation__link">Темы аккаунта и тезисы</a>
                  </li>
                  <li v-hover-to="'kindSett'" class="navigation__item">
                    <a class="navigation__link">Форматы рекламы</a>
                  </li>
                </template>
                <li v-hover-to="'defSett'" class="navigation__item">
                  <a class="navigation__link">Общие настройки</a>
                </li>
                <li v-hover-to="'notifSett'" class="navigation__item">
                  <a class="navigation__link">Уведомления</a>
                </li>
              </ul>
            </nav>
          </VueStickySidebar>
        </div>
      </div>
    </div>
    <ChangePhoneModal
      @close="showPhoneModal = false"
      :open="showPhoneModal"
      v-if="showPhoneModal"
    />
    <ActivateEmailNotification
      @hide="showEmailActivation = false"
      v-if="showEmailActivation"
      :showEmailActivation="showEmailActivation"
    />
    <FillExample @close="showFillExample = false" :open="showFillExample" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { required, minLength, minValue, maxValue, between, email } from 'vuelidate/lib/validators';
import PhoneMaskInput from 'vue-phone-mask-input';
import CitySelect from '@main/components/reusable/elements/selects/CitySelect.vue';
import VueStickySidebar from 'vue-sticky-sidebar';
import Multiselect from 'vue-multiselect';
import auth from '@main/api/auth';
import ChangePhoneModal from '@main/components/reusable/modals/ChangePhoneModal.vue';
import ActivateEmailNotification from '@main/components/reusable/modals/ActivateEmailNotification.vue';
import FillExample from '@main/components/reusable/modals/FillExample.vue';
import bloggers from '@main/api/bloggers';
import StatisticFormValidationMixin from '@main/mixins/statistic/StatisticFormValidationMixin.vue';
import SingleFileInput from '@main/components/forms/SingleFileInput.vue';
import crypto from '@main/func/crypto';
import position from '@main/func/position';
import alertGreyIcon from '@main/assets/img/svg/allert_grey.svg';
import SettingsBlock from './SettingsBlock.vue';
import PrTypeForm from '../reusable/statistics/PrTypeForm.vue';

export default {
  components: {
    SingleFileInput,
    SettingsBlock,
    PrTypeForm,
    VueStickySidebar,
    Multiselect,
    ChangePhoneModal,
    PhoneMaskInput,
    ActivateEmailNotification,
    FillExample,
    CitySelect,
  },
  mixins: [StatisticFormValidationMixin],
  data: () => ({
    error: '',
    email: '',
    bloggerLinkCopied: false,
    coverageScreen: null,
    countryScreen: null,
    audienceScreen: null,
    postCoverageScreen: null,
    cityScreen: null,
    womenScreen: null,
    fileLoading: false,
    ageGroup: 0,
    prTypeModel: null,
    model: null,
    currentItem: null,
    showPhoneModal: false,
    showEmailActivation: false,
    showFillExample: false,
    legalStatusMapping: {
      2: {
        name: 'ИНН',
        model: 'inn',
      },
      3: {
        name: 'ОГРНИП',
        model: 'ogrnip',
      },
      4: {
        name: 'ОГРН',
        model: 'ogrn',
      },
    },
  }),
  watch: {
    // eslint-disable-next-line func-names
    'model.blogger_profile.legal_status': function (val) {
      if (val === 1) {
        console.log('awdawd');
        this.model.blogger_profile.ogrnip = null;
        console.log(this.model.blogger_profile.ogrnip);
      }
    },
  },
  computed: {
    ...mapState(['bloggerOptions', 'currentUser', 'countries', 'tags']),
    options() {
      return this.bloggerOptions.actions.POST;
    },
    currentAudience() {
      return this.options.audiences.child.children.age_group.choices.filter(
        (val) => val.value === this.model.blogger_profile.main_audience.age_group
      )[0];
    },
    legalStatusList() {
      const chunkArray = (myArray, chunkSize) => {
        let index = 0;
        const arrayLength = myArray.length;
        const tempArray = [];
        let myChunk;

        for (index = 0; index < arrayLength; index += chunkSize) {
          myChunk = myArray.slice(index, index + chunkSize);
          tempArray.push(myChunk);
        }

        return tempArray;
      };
      return chunkArray(this.options.legal_status.choices, 2);
    },
    bloggerLink() {
      return `${window.location.origin}/#/bloggers/${this.currentUser.blogger_profile.instaname}/?access=${this.currentUser.blogger_profile.da_token}`;
    },
    modelChanged() {
      return !this.lodash.isEqual(this.currentUser, this.model);
    },
  },
  methods: {
    ...mapActions(['loadUser']),
    ...mapMutations('popups', ['toggleChangePasswordModal', 'toggleInstagramVerificationModal']),
    validateEmail(mail) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(mail).toLowerCase());
    },
    increment() {
      const mod = this.lodash.cloneDeep(this.prTypeModel);
      mod.uid = this.getUid();
      this.model.blogger_profile.pr_types.push(mod);
    },
    decompose(uid) {
      this.model.blogger_profile.pr_types.splice(this.getIdxById(uid), 1);
    },
    getIdxById(uid) {
      return this.model.blogger_profile.pr_types.indexOf(
        this.model.blogger_profile.pr_types.filter((val) => val.uid === uid)[0]
      );
    },
    async changeEmail() {
      try {
        const response = await auth.changeEmail(this.model.profile.email);
        if (response.success) {
          this.$store.commit('notifications/showMessage', {
            title: 'E-mail успешно изменен, требуется подтверждение!',
            icon: 1,
            id: 837483,
          });
        }
        this.loadUser();
      } catch (e) {
        this.$store.commit('notifications/showMessage', {
          title:
            'Ошибка при изменении E-mail! Попробуйте позже или обратитесь в техническую поддержку',
          iconPath: alertGreyIcon,
        });
      }
    },
    async submit() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        try {
          await auth.updateMe(this.model);
          this.$store.commit('notifications/showMessage', {
            title: 'Настройки изменены!',
            icon: 1,
          });
          await this.loadUser();
        } catch (e) {
          if (e.response.status >= 500) {
            this.$store.commit('notifications/setWindow', {
              iconPath: alertGreyIcon,
              text:
                'Возникла ошибка при изменении настроек, попробуйте позднее или обратитесь в техническую поддержку - help@easyprbot.com!',
            });
          }
        }
      } else {
        this.$store.commit('notifications/showMessage', {
          title: 'Введите корректные данные!',
          icon: 2,
        });
      }
    },
    getUid() {
      return this.model.blogger_profile.pr_types.length + Math.floor(Math.random() * 100000);
    },
    async uploadScreens(data) {
      await bloggers.uploadScreens(data);
    },
    async toggleEmailNotifications(val) {
      await auth.updateMe({
        id: 36260,
        profile: { ...this.lodash.cloneDeep(this.currentUser.profile), accept_email: val },
      });
      await this.loadUser();
    },
  },
  mounted() {
    this.$set(this, 'model', this.lodash.cloneDeep(this.currentUser));
    if (
      this.model.blogger_profile &&
      this.model.blogger_profile.pr_types &&
      this.model.blogger_profile.pr_types.length
    ) {
      const prTypes = this.lodash.cloneDeep(this.model.blogger_profile.pr_types);
      this.model.blogger_profile.pr_types = prTypes.map((val) => {
        return { ...val, ...{ uid: this.getUid() } };
      });
    }
    this.$set(
      this,
      'prTypeModel',
      this.lodash.cloneDeep(this.bloggerOptions.actions.POST.pr_types.child.children)
    );
    const keys = Object.keys(this.prTypeModel);
    for (let i = 0; i < keys.length; i += 1) {
      this.prTypeModel[keys[i]] = null;
    }
    this.prTypeModel.uid = this.getUid();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .multiselect__select {
  height: 33px !important;
  top: 0px !important;
}

::v-deep .multiselect__tag {
  margin-bottom: 0px !important;
}

::v-deep .multiselect__placeholder {
  margin-bottom: 0px !important;
}
::v-deep .multiselect__single {
  display: block ruby;
  margin: auto auto !important;
  line-height: 22px;
  white-space: nowrap !important;
  max-width: 150px;
  overflow-x: hidden !important;
  text-overflow: ellipsis !important;
}
::v-deep .multiselect__tags {
  padding: 5px 40px 1px 8px !important;
  border: 1px solid #d5dfe4 !important;
  min-height: 36px !important;
  max-width: 100% !important;
  width: 100% !important;
  min-width: 0px !important;
}
::v-deep .multiselect {
  border-radius: 5px !important;
  width: 100% !important;
  min-height: 36px !important;
  min-width: 0px !important;
}
.settings__info--block.sett__inp--percent::after {
  content: '%';
  position: absolute;
  right: 11px;
  top: 9px;
}
.navigation {
  width: 100% !important;
}
#settings__nav {
  width: 23%;
  height: 3062px;
  display: block;
}
@media (max-width: 1024px) {
  #settings__nav {
    display: none !important;
  }
}
.btn__uploaded {
  width: 120px;
  height: 36px;
}
.format_pr-btn-sett {
  margin-top: 30px;
}
::v-deep .kinds-sett-content {
  padding: 17px !important;
}
.settings__input {
  text-align: left;
}
.pay-btn {
  width: 145px;
  height: 37px;
}
.input--wd {
  text-align: left !important;
}
@media (max-width: 422px) {
  .mobile__flx-wrap {
    flex-wrap: wrap;
  }
  .settings__themes--wrap {
    margin-right: 0px !important;
  }
  .settings__themes--textarea {
    width: 100% !important;
  }
  .settings__container {
    padding: 10px !important;
  }
  .section.section__settings.sect_sett__closed {
    display: none !important;
  }
  .payment__radios {
    flex-direction: column;
  }
  .payment-radio__wrap {
    width: 100% !important;
    height: 60px !important;
    margin-right: 0px !important;
    margin-bottom: 10px !important;
  }
  .payment__radio {
    margin-right: 0px !important;
  }
  .payment-radio__text {
    flex-direction: row !important;
  }
  .label.settings__radio--button {
    margin-bottom: 0px !important;
  }
}

::v-deep .stata__textarea {
  width: 97% !important;
}
::v-deep .stata__radio {
  width: 100% !important;
}

.pr-types-sett__footer {
  padding-left: 0px;
  padding-bottom: 0px;
}

.set-fields {
  max-width: 350px !important;
}

@media (max-width: 760px) {
  .set-fields {
    max-width: 400px !important;
  }
}

@media (min-width: 999px) {
  .container {
    max-width: 1350px !important;
  }
}
::v-deep .fmt-rd {
  min-width: 50px !important;
}
</style>
