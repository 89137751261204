<script>
export default {
  data: () => ({
    currentStep: 1,
    steps: 1,
  }),
  methods: {
    goLeft() {
      if (this.currentStep > 1) {
        this.currentStep -= 1;
      } else {
        this.$emit('close');
      }
    },
    goRight() {
      if (this.currentStep < this.steps) {
        this.currentStep += 1;
      }
    },
  },
};
</script>
